.card {
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: #283048; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #859398,
    #283048
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #859398,
    #283048
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.vign-rea {
  background-color: #fff;
  border-radius: 10px;
  border: solid 5px $color-1;
  margin: 10px 0px 10px 0px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.text-rea {
  font-size: 20px;
}
.rea-lien {
  text-decoration: none;
  color: #283048;
}
.rea-lien:hover {
  text-decoration: none;
  color: #283048;
}

.vign-rea:hover {
  background-color: $color-2;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
