@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

$font-1: "Roboto", sans-serif;
$color-1: rgb(59, 147, 27);
$color-2: rgba(242, 133, 0, 1);
// Base;
* {
  box-sizing: border-box;
  font-family: $font-1;
}
main {
  background-image: repeating-linear-gradient(
      45deg,
      rgba(194, 194, 194, 0.192) 0px,
      rgba(151, 150, 150, 0.1) 2px,
      transparent 2px,
      transparent 4px
    ),
    linear-gradient(
      90deg,
      rgb(255, 255, 255),
      rgb(255, 255, 255),
      rgb(255, 255, 255)
    );
}
.txt-intro {
  font-size: 23px;
}

.gras-intro {
  color: transparent;
  background: rgb(209, 119, 15);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
  font-size: 25px;
}
.gras {
  color: transparent;
  background: rgb(209, 119, 15);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
  font-size: large;
}
/*  VERT & ORANGE
 background: rgb(59, 147, 27);
  background: linear-gradient(
    210deg,
    rgb(59, 147, 27) 79%,
    rgba(242, 133, 0, 1) 79%
  );
 */
.Green {
  background: #283048; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #adb5b7,
    #283048
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #adb5b7,
    #283048
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

a.dropdown-item {
  background-color: #adb5b7;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 550;
}
a.dropdown-item:hover {
  background-color: #adb5b7;
  color: rgb(255, 146, 63);
  text-decoration: underline;
}
div.dropdown-menu.show {
  background-color: #adb5b7;
}
a.nav-link {
  color: white;
  font-size: 20px;
  font-weight: 550;
}
a {
  color: white;
}

a.nav-link:hover {
  color: rgb(255, 146, 63);
  text-decoration: underline;
}

.ssligne {
  margin-bottom: 10px;
}

.ssligne:after {
  display: block;
  height: 5px;
  background: rgb(59, 147, 27);
  background: linear-gradient(
    218deg,
    rgb(59, 147, 27) 50%,
    rgba(242, 133, 0, 1) 50%
  );
  width: 100px;
  content: "";
  margin: 0 auto;
}
.ssligne2 {
  margin-bottom: 10px;
}

.ssligne2:after {
  display: block;
  height: 4px;
  background: rgba(242, 133, 0, 1);
  background: linear-gradient(
    220deg,
    rgba(242, 133, 0, 1) 50%,
    rgb(143, 143, 143) 50%
  );
  width: 100px;
  content: "";
  margin: 0 auto;
}

.ssligne3 {
  margin-bottom: 10px;
}

.ssligne3:after {
  display: block;
  height: 4px;
  background: $color-1;
  background: linear-gradient(220deg, $color-1 50%, rgb(143, 143, 143) 50%);
  width: 100px;
  content: "";
  margin: 0 auto;
}
.scale:hover {
  transform: scale(1.06);
  transition: all 0.5s;
  transition-timing-function: ease-out;
}
.linkb {
  color: black;
  text-decoration: none;
}
.linkb:hover {
  color: rgb(255, 117, 25);
  text-decoration: none;
}

.linkw {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.linkw:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.linko {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.linko:hover {
  color: rgb(255, 117, 25);
  text-decoration: none;
}

.btn-map {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  margin: 10px;
  padding: 15px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: #ff512f;
  border-radius: 15px;
  text-decoration: none;
  transition: all 1s;
  transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);
  &:hover {
    transform: scale(1.1, 1.1);
  }

  &:active {
    transform: scale(1.05, 1.05);
  }
}

.btn-map:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.contact {
  padding: 30px;
}
.btn-contact {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  margin: 0;
  padding: 20px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: #ff512f;
  border-radius: 15px;
  text-decoration: none;
  transition: all 1s;
  font-weight: 600;
  transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);
  &:hover {
    transform: scale(1.1, 1.1);
  }
}
.btn-contact:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  margin: 10px;
  padding: 15px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: #ff512f;
  border-radius: 15px;
  text-decoration: none;
  transition: all 1s;
  font-weight: 600;
  transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);
  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.justif {
  text-align: justify;
  text-justify: inter-word;
}
.justif2 {
  text-justify: inter-word;
  white-space: break-spaces;
}
.img-border {
  border-radius: 15px;
}

.user {
  font-weight: 900;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  color: transparent;
}
.user:after {
  display: block;
  height: 3px;
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  width: 150px;
  content: "";
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .btn-grad {
    background-image: linear-gradient(
      to right,
      #ff512f 0%,
      #f09819 51%,
      #ff512f 100%
    );
    margin: 3px;
    padding: 10px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border: #ff512f;
    border-radius: 15px;
    text-decoration: none;
    transition: all 1s;
    transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);
    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
}
@media (max-width: 990px) {
  a.nav-link {
    color: white;
    font-size: 17px;
  }
  a.dropdown-item {
    font-size: 17px;
  }
  .txt-intro {
    font-size: 18px;
  }
  .gras-intro {
    color: transparent;
    background: rgb(209, 119, 15);
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: bold;
    font-size: 20px;
  }
}
