.map {
  position: relative;
  padding-bottom: 80%;
  height: 0;
  overflow: hidden;
  margin-top: 20px;
}

.map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
